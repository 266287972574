import {IWidgetControllerConfig} from '@wix/native-components-infra/dist/src/types/types'
import {PlanList} from '@wix/wix-events-commons-statics/dist/types/exported-types'
import {UPDATE_SITE_SETTINGS} from '../../commons/actions/site-settings'
import {SiteSettingsSettings} from '../../commons/types/state'
import {Api as BaseApi} from '../../commons/utils/api'
import {getLanguage, getViewMode} from '../../commons/utils/wix-code-api'
import {GET_CHECKOUT_OPTIONS} from '../actions/checkout-options'
import {PUBLISH_COMPONENT_SETTINGS, UPDATE_COMPONENT_SETTINGS_DRAFT} from '../actions/component'
import {GET_EVENT, GET_MEMBERS} from '../actions/event'
import {FETCH_CURRENT_MEMBER, PROMPT_LOGIN} from '../actions/members'
import {GET_PLAN_LIST} from '../actions/paid-plans'
import {CANCEL_RESERVATION, RESERVE_TICKETS} from '../actions/reservation'
import {GET_MEMBER_RSVP, SEND_RSVP, UPDATE_RSVP, UPDATE_RSVP_STATUS, DELETE_RSVP} from '../actions/rsvp'
import {ComponentSettings, RegFormData, SelectedTickets} from '../types'
import {UPDATE_ORDER, PLACE_ORDER, GET_ORDER} from '../actions/placed-order'
import {GET_DISCOUNT} from '../actions/checkout'
import {GET_POLICIES} from '../actions/policies'
import {extractFormData} from './api-data-mapper'
import TicketReservationQuantity = wix.events.ticketing.TicketReservationQuantity

export class Api extends BaseApi {
  constructor(controller: IWidgetControllerConfig) {
    super(controller)

    this.registrar = {
      [GET_EVENT.NAME]: this.getEvent,
      [GET_MEMBERS.NAME]: this.getMembers,
      [GET_CHECKOUT_OPTIONS.NAME]: this.getCheckoutOptions,
      [RESERVE_TICKETS.NAME]: this.makeReservation,
      [CANCEL_RESERVATION.NAME]: this.cancelReservation,
      [GET_MEMBER_RSVP.NAME]: this.getMemberRsvp,
      [SEND_RSVP.NAME]: this.createRsvp,
      [DELETE_RSVP.NAME]: this.deleteRsvp,
      [UPDATE_RSVP.NAME]: this.updateRsvp,
      [PROMPT_LOGIN.NAME]: this.promptLogin,
      [GET_PLAN_LIST.NAME]: this.getPlanList,
      [UPDATE_ORDER.NAME]: this.updateOrder,
      [PLACE_ORDER.NAME]: this.placeOrder,
      [GET_ORDER.NAME]: this.getOrder,
      [FETCH_CURRENT_MEMBER.NAME]: this.getCurrentMember,
      [UPDATE_RSVP_STATUS.NAME]: this.updateRsvpStatus,
      [GET_DISCOUNT.NAME]: this.getInvoice,
      [UPDATE_COMPONENT_SETTINGS_DRAFT.NAME]: this.updateComponentDraftSettings,
      [PUBLISH_COMPONENT_SETTINGS.NAME]: this.publishComponentSettings,
      [UPDATE_SITE_SETTINGS.NAME]: this.updateSiteSettings,
      [GET_POLICIES.NAME]: this.getPolicies,
    }
  }

  getData = (slug: string, responsive = false) => {
    const {compId, wixCodeApi} = this.controller
    const language = getLanguage(wixCodeApi)
    const viewMode = getViewMode(wixCodeApi)
    const url = `/html/page-data/${slug}?compId=${compId}&locale=${language}&viewMode=${viewMode}&responsive=${responsive}`

    return this.api.get(url)
  }

  getOrder = async (eventId: string, orderNumber: string, token: string = ''): Promise<wix.events.ticketing.Order> => {
    if (token) {
      return this.api.get(`/web/orders/by-token?token=${token}`)
    } else {
      return this.api.get(`/web/events/${eventId}/orders/${orderNumber}`)
    }
  }

  placeOrder = ({
    eventId,
    buyer,
    guests,
    couponCode,
    memberId,
    planOrderId,
    benefitId,
    policyAgreementToken,
  }: PlaceOrderArgs) =>
    this.checkout({
      eventId,
      buyer: extractFormData(buyer),
      guests,
      reservationId: buyer.reservation,
      couponCode,
      memberId,
      planOrderId,
      benefitId,
      policyAgreementToken,
    })

  checkout = ({
    eventId,
    buyer,
    guests,
    reservationId,
    couponCode,
    memberId,
    planOrderId,
    benefitId,
    policyAgreementToken,
  }: CheckoutArgs): Promise<{order: wix.events.ticketing.Order}> =>
    this.api.post(`/web/events/${eventId}/checkout`, {
      [guests ? 'buyer' : 'data']: buyer,
      guests,
      reservationId,
      memberId,
      couponCode,
      planOrderId,
      benefitId,
      policyAgreementToken,
    })

  updateOrder = (
    eventId: string,
    orderNumber: string,
    buyer: RegFormData,
    guests: RegFormData[],
  ): Promise<wix.events.ticketing.UpdateOrderResponse> =>
    this.api.put(`/web/events/${eventId}/checkout/${orderNumber}`, {buyer, guests})

  getEvent = (eventId: string) => this.api.get(`/web/events/${eventId}/viewer`)

  getMembers = (eventId: string): Promise<{members: Member[]; totalGuests: TotalEventGuests}> =>
    this.api.get(`/web/events/${eventId}/members`, {useModifyBaseUrl: true})

  getInvoice = (
    eventId: string,
    reservationId: string,
    couponCode?: string,
    benefitId?: string,
    planOrderId?: string,
  ): Promise<{id: string; expires: string}> => {
    return this.api.put(`/web/events/${eventId}/invoice`, {reservationId, couponCode, benefitId, planOrderId})
  }

  getCheckoutOptions = (): Promise<wix.events.ticketing.GetCheckoutOptionsResponse> =>
    this.api.get('/web/checkout-options')

  makeReservation = (eventId: string, tickets: SelectedTickets): Promise<{id: string; expires: string}> => {
    const ticketQuantities: TicketReservationQuantity[] = Object.entries(tickets).map(
      ([ticketDefinitionId, quantity]) => ({
        ticketDefinitionId,
        quantity,
      }),
    )
    return this.api.post(`/web/events/${eventId}/reservations`, ticketQuantities)
  }

  cancelReservation = (eventId: string, reservationId: string) =>
    this.api.delete(`/web/events/${eventId}/reservations/${reservationId}`)

  getMemberRsvp = async (eventId: string, memberId: string): Promise<wix.events.rsvp.Rsvp> => {
    return this.api.get(`/web/events/${eventId}/member-rsvp?memberId=${memberId}`).then(response => response.rsvp)
  }

  getCurrentMember = (): Promise<{
    lastName: string
    firstName: string
    loginEmail: string
    emails: string[]
    id: string
  }> => this.api.get(`/web/members/current`).then(response => response.member)

  createRsvp = (
    eventId: string,
    data: RegFormData,
    status: wix.events.rsvp.RsvpStatus,
    memberId?: string,
    policyAgreementToken?: string,
  ): Promise<wix.events.rsvp.CreateRsvpResponse> => {
    return this.api.post(`/web/events/${eventId}/v2/rsvp`, {memberId, status, data, policyAgreementToken})
  }

  deleteRsvp = (eventId: string, rsvpId: string) =>
    this.api.delete(`/web/events/${eventId}/rsvp/${rsvpId}`).then(() => ({eventId}))

  updateRsvp = (
    eventId: string,
    data: RegFormData,
    status: wix.events.rsvp.RsvpStatus,
    rsvpId: string,
  ): Promise<wix.events.rsvp.UpdateRsvpResponse> => {
    return this.api.put(`/web/events/${eventId}/rsvp/${rsvpId}/form`, {data, status})
  }

  updateRsvpStatus = (
    eventId: string,
    rsvpId: string,
    status: wix.events.rsvp.RsvpStatus,
  ): Promise<{eventId: string}> =>
    this.api.put(`/web/events/${eventId}/rsvp/${rsvpId}/status`, {status}).then(() => ({eventId}))

  promptLogin = (lang: string) => {
    return new Promise(async (resolve, reject) => {
      try {
        await this.controller.wixCodeApi.user.promptLogin({
          mode: 'login',
          lang,
        })
        resolve(true)
      } catch (e) {
        if (typeof e !== 'string') {
          reject(e)
          return
        }
        resolve(false)
      }
    })
  }

  getPlanList = (eventId: string): Promise<{plans: PlanList}> => this.api.get(`/web/plans?eventId=${eventId}`)

  updateComponentDraftSettings = (settings: ComponentSettings) =>
    this.api.put(`/web/component/${this.controller.compId}/draft`, {component: {settings}})

  publishComponentSettings = (settings: ComponentSettings) =>
    this.api.put(`/web/component/${this.controller.compId}`, {component: {settings}})

  updateSiteSettings = (settings: SiteSettingsSettings) => this.api.put(`/web/site-settings`, {settings})

  getPolicies = (eventId: string) => this.api.get(`/web/events/${eventId}/policies?withAgreementToken=true`)
}

interface PlaceOrderArgs {
  eventId: string
  buyer: RegFormData
  guests: RegFormData[]
  couponCode: string
  memberId: string
  planOrderId: string
  benefitId: string
  policyAgreementToken: string
}

interface CheckoutArgs {
  eventId: string
  buyer: RegFormData
  guests: RegFormData[]
  reservationId: string
  couponCode?: string
  memberId?: string
  planOrderId?: string
  benefitId?: string
  policyAgreementToken?: string
}
