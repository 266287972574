import {IWixAPI} from '@wix/native-components-infra/dist/src/types/types'
import {
  EventStatus,
  EventType,
  RegistrationStatus,
  LocationType,
  getAboutText,
  isEndDateHidden,
  isLocationTbd,
} from '@wix/wix-events-commons-statics'
import {getPageUrl} from '../../commons/utils/wix-code-api'
import {State} from '../types'

export const renderSEOTags = async (wixCodeApi: IWixAPI, state: State) => {
  const pageUrl = await getPageUrl(wixCodeApi)
  const itemData = getItemData(state, pageUrl)
  const seoData = state.event.seoSettings?.advancedSeoData ?? {}

  if (itemData) {
    await wixCodeApi.seo.renderSEOTags({
      itemType: 'EVENTS_PAGE',
      itemData,
      seoData,
    })
  }
}

const getItemData = (state: State, pageUrl: string) => {
  try {
    return {
      event: transformEvent(state.event, pageUrl),
      tickets: state.tickets,
    }
  } catch (e) {
    return null
  }
}

export const transformEvent = (event: wix.events.Event, pageUrl: string) => ({
  ...event,
  about: getAboutText(event),
  status: EventStatus[event.status],
  eventPageUrl: {
    base: pageUrl,
    path: `/${event.slug}`,
  },
  registration: {
    ...event.registration,
    type: EventType[event.registration.type],
    status: RegistrationStatus[event.registration.status],
  },
  location: {
    ...event.location,
    type: isLocationTbd(event) ? undefined : LocationType[event.location.type],
  },
  scheduling: {
    ...event.scheduling,
    config: {
      ...event.scheduling.config,
      endDate: isEndDateHidden(event) ? undefined : event.scheduling.config.endDate,
    },
  },
})
